import { ReactNode, useContext } from "react";
import { FaAlert } from "@/components/FaAlert/FaAlert";
import { ToastContext } from "@/components/ToastContext";
import { recordError } from "@/util/recordError";

type DeleteAlertProps = {
    message: ReactNode;
    header: string;
    onDidDismiss: () => void;
    onDelete?: () => void;
    cancelText?: string;
    confirmText?: string;
    show: boolean;
    className?: string;
};

export function DeleteAlert(props: DeleteAlertProps) {
    const { onMessage: onError } = useContext(ToastContext);

    const handleDelete = () => {
        try {
            props.onDelete?.();
        } catch (e: any) {
            recordError("Error while deleting something.", e);
            onError("Fehler beim Löschen. Bitte versuche es später erneut.", "danger");
        }
    };

    return (
        <FaAlert
            isOpen={props.show}
            onDismiss={props.onDidDismiss}
            title={props.header}
            message={props.message}
            className={props.className}
            actions={[
                {
                    text: props.cancelText ?? "Behalten",
                    color: "dark",
                },
                {
                    text: props.confirmText ?? "Ja",
                    handler: () => handleDelete(),
                },
            ]}
        />
    );
}
