import { UpdateData } from "firebase/firestore";
import { Address } from "./Address";
import { CountryCode } from "./CountryCode";
import { CustomerTag } from "./CustomerTag";
import { OperatingUnit } from "./OperatingUnit";
import { PaymentType } from "./PaymentType";
import { SharingTokenType } from "./SharingToken";
import { Vertex } from "./Vertex";
import { notNullish } from "../utils/array";
import { generateSearchableSubstrings } from "../utils/generateSearchableSubstrings";

export enum CustomerConnectionStatus {
    NOT_CONNECTED = "NOT_CONNECTED",
    INVITED = "INVITED",
    CONNECTED = "CONNECTED",
}

export enum Salutation {
    MR = "MR",
    MRS = "MRS",
    DOCTOR = "DOCTOR",
    PROFESSOR = "PROFESSOR",
    COMPANY = "COMPANY",
    FAMILY = "FAMILY",
    FARMER = "FARMER",
    BIOGAS_PLANT = "BIOGAS_PLANT",
    CITY = "CITY",
    /**
     * Gemeinde
     */
    TOWNSHIP = "TOWNSHIP",
    MARKET_TOWN = "MARKET_TOWN",
    FARM = "FARM",
    AGRICULTURAL_CONTRACTOR = "AGRICULTURAL_CONTRACTOR",
    /**
     * Gut bzw. Gutshof
     */
    ESTATE = "ESTATE",
    /**
     * Landwirtschaft
     */
    HUSBANDRY = "HUSBANDRY",
    COMMUNE = "COMMUNE",
}

export type CustomerContactPerson = {
    id: string;
    isPrimary: boolean;
    receivesReceiptMails: boolean;
    visibleInOrders: boolean;
    salutation: Salutation | null;
    firstName: string;
    lastName: string;
    mail: string;
    phone: string;
    mobile: string;
};

export function getSalutationName(salutation: CustomerContactPerson["salutation"]): string {
    switch (salutation) {
        case Salutation.MR:
            return "Herrn";
        case Salutation.MRS:
            return "Frau";
        case Salutation.DOCTOR:
            return "Dr.";
        case Salutation.PROFESSOR:
            return "Prof.";
        case Salutation.COMPANY:
            return "Firma";
        case Salutation.FAMILY:
            return "Familie";
        case Salutation.FARMER:
            return "Landwirt";
        case Salutation.BIOGAS_PLANT:
            return "Biogasanlage";
        case Salutation.CITY:
            return "Stadt";
        case Salutation.TOWNSHIP:
            return "Gemeinde";
        case Salutation.MARKET_TOWN:
            return "Markt";
        case Salutation.FARM:
            return "Hof";
        case Salutation.AGRICULTURAL_CONTRACTOR:
            return "Lohnunternehmen";
        case Salutation.ESTATE:
            return "Gut";
        case Salutation.HUSBANDRY:
            return "Landwirtschaft";
        case Salutation.COMMUNE:
            return "Kommune";
    }
    return "";
}

export type CustomerPaymentInfos = {
    paymentType: PaymentType;
    paymentDueDays: number;
    discountPercent: number;
    cashDiscountPercent: number;
    cashDiscountDueDays: number;
};

export const DEFAULT_CUSTOMER_PAYMENT_INFOS: CustomerPaymentInfos = {
    paymentType: PaymentType.INVOICE,
    paymentDueDays: 30,
    discountPercent: 0,
    cashDiscountPercent: 0,
    cashDiscountDueDays: 0,
};

export class Customer {
    public active: boolean;
    public addressCity: string;
    public addressStreet: string;
    public addressZip: string;
    public addressCountryCode: CountryCode;
    public alias: string;
    public appUserId: string | null;
    public archived: boolean;
    public buyerReference: string; // German "Leitstellen-ID"
    public cashDiscountDueDays: number | null; // Skontofrist
    public cashDiscountPercent: number | null; // Skonto
    public companyNumber: string; //Betriebsnummer
    public connectionStatus: CustomerConnectionStatus;
    public contactPersons: CustomerContactPerson[];
    public contractExpireNotification: string | null; // YYYY-MM-DD
    public customerNumber: number | null;
    public deliveryAddress: Address;
    public discountPercent: number | null; // Rabatt
    public enabledSharingTypes: SharingTokenType[];
    public id: string;
    public latLng: Vertex | null;
    public name: string;
    public mail: string;
    public phone: string;
    public mobilePhoneNumber: string;
    public note: string;
    public operatingUnitIds: Array<OperatingUnit["id"]>;
    public paymentCollectionThroughMaschinenring: boolean;
    public paymentDueDays: number | null; // Zahlungsziel
    public paymentNotes: string;
    public paymentType: PaymentType | null;
    public salesTaxId: string; // Umsatzsteuer-ID
    public taxId: string; // Steuer-ID
    /**
     * decides, if the customer's account can edit the shape / delete structures
     */
    public canEditStructures: boolean;
    /**
     * only for use in queries, set via triggers
     */
    public _searchableSubstrings: string[];

    public readonly displayName: string;
    public readonly ordersWithoutBill: OrdersWithoutBill;
    public readonly ordersWithoutDeliveryNote: OrdersWithoutDeliveryNote;

    public tagId: CustomerTag["id"] | null;

    constructor(initialValues?: Partial<Customer>) {
        this.active = initialValues?.active ?? true;
        this.addressStreet = initialValues?.addressStreet ?? "";
        this.addressZip = initialValues?.addressZip ?? "";
        this.addressCity = initialValues?.addressCity ?? "";
        this.addressCountryCode = initialValues?.addressCountryCode ?? "DE";
        this.alias = initialValues?.alias ?? "";
        this.appUserId = initialValues?.appUserId ?? null;
        this.archived = initialValues?.archived ?? false;
        this.buyerReference = initialValues?.buyerReference ?? "";
        this.cashDiscountDueDays = initialValues?.cashDiscountDueDays ?? null;
        this.cashDiscountPercent = initialValues?.cashDiscountPercent ?? null;
        this.companyNumber = initialValues?.companyNumber ?? "";
        this.connectionStatus = initialValues?.connectionStatus ?? CustomerConnectionStatus.NOT_CONNECTED;
        this.contactPersons = initialValues?.contactPersons ?? [];
        this.contractExpireNotification = initialValues?.contractExpireNotification ?? null;
        this.customerNumber = initialValues?.customerNumber ?? null;
        this.discountPercent = initialValues?.discountPercent ?? null;
        this.deliveryAddress = initialValues?.deliveryAddress ?? {
            name: "",
            city: "",
            zip: "",
            street: "",
            countryCode: "DE",
        };
        this.enabledSharingTypes = initialValues?.enabledSharingTypes ?? [];
        this.id = initialValues?.id ?? "";
        this.latLng = initialValues?.latLng ?? null;
        this.name = initialValues?.name ?? "";
        this.mail = initialValues?.mail ?? "";
        this.phone = initialValues?.phone ?? "";
        this.mobilePhoneNumber = initialValues?.mobilePhoneNumber ?? "";
        this.note = initialValues?.note ?? "";
        this.operatingUnitIds = initialValues?.operatingUnitIds ?? [];
        this.paymentCollectionThroughMaschinenring = initialValues?.paymentCollectionThroughMaschinenring ?? false;
        this.paymentNotes = initialValues?.paymentNotes ?? "";
        this.paymentType = initialValues?.paymentType ?? null;
        this.paymentDueDays = initialValues?.paymentDueDays ?? null;
        this.salesTaxId = initialValues?.salesTaxId ?? "";
        this.taxId = initialValues?.taxId ?? "";
        this.canEditStructures = initialValues?.canEditStructures ?? false;
        this._searchableSubstrings = initialValues?._searchableSubstrings ?? [];
        this.ordersWithoutBill = initialValues?.ordersWithoutBill ?? {};
        this.ordersWithoutDeliveryNote = initialValues?.ordersWithoutDeliveryNote ?? {};

        const primaryContactPerson = initialValues?.contactPersons?.find(contactPerson => {
            return contactPerson.isPrimary;
        });
        this.displayName =
            initialValues?.displayName ||
            initialValues?.name ||
            [primaryContactPerson?.lastName, primaryContactPerson?.firstName].filter(Boolean).join(" ");
        this.tagId = initialValues?.tagId ?? null;
    }
}

export type OrdersWithoutBill = Record<OperatingUnit["id"], boolean>;
export type OrdersWithoutDeliveryNote = Record<
    OperatingUnit["id"],
    {
        // the customer has orders that are billed but without a delivery note
        billed: boolean;
        // the customer has orders that are not billed and without a delivery note
        unbilled: boolean;
    }
>;

export function isCustomer(object: any): object is Customer {
    if (!object) {
        return false;
    }
    return (
        typeof object === "object" &&
        typeof object.addressStreet === "string" &&
        typeof object.addressZip === "string" &&
        typeof object.addressCity === "string"
    );
}

export function generateSearchableSubstringsForCustomer(customer: Partial<Customer>) {
    const searchableCustomerAttributes: string[] = [
        customer.name?.substring(0, 50),
        customer.alias?.substring(0, 50),
        customer.customerNumber?.toString(),
        customer.addressCity,
        customer.addressZip,
    ].filter(notNullish);

    const primaryContactPerson = customer.contactPersons?.find(contactPerson => {
        return contactPerson.isPrimary;
    });
    if (primaryContactPerson) {
        searchableCustomerAttributes.push(`${primaryContactPerson.firstName} ${primaryContactPerson.lastName}`);
        searchableCustomerAttributes.push(`${primaryContactPerson.lastName} ${primaryContactPerson.firstName}`);
    }

    return generateSearchableSubstrings(searchableCustomerAttributes);
}

export type WriteableCustomer = {
    -readonly [Key in keyof Customer]: Customer[Key];
};

export function preparePartialCustomerForFirestore(customer: UpdateData<Customer>): UpdateData<Customer> {
    const prepared: UpdateData<WriteableCustomer> = { ...customer };

    delete prepared.ordersWithoutBill;
    delete prepared.ordersWithoutDeliveryNote;

    return prepared;
}
