import { Describe, Struct, array, enums, literal, number, object, string, union } from "superstruct";
import { AnyDriverQueryTemplate } from "./DriverQuery";
import { AnyPriceBlock, ProtocolActionConfig } from "./Service";
import { ServicePriceUnit } from "./ServicePriceUnit";
import { ActivityPreset } from "../AppCompany";
import { MachineCounterType } from "../MachineCounterTracking";
import { ScalePrice } from "../PriceBlocks/ScalePrice";

export enum PriceStructureType {
    SIMPLE = "SIMPLE",
    TIERED = "TIERED",
    VOLUME = "VOLUME",
}

export enum PricePlaceholderType {
    MACHINE_PRICE = "MACHINE_PRICE",
}

export type WorkTypeRestriction = Array<ActivityPreset["id"]>;

export type ServicePriceBlockBase = {
    id: string; // uuid
    receiptText: string;
    maschinenring: {
        fuelTarget: boolean;
        vskz: string | null;
    };
};

export type CalculatedServicePriceBlock = ServicePriceBlockBase & {
    workTypeRestriction: WorkTypeRestriction | null;
    basis: ServicePriceUnit | MachineCounterType;
    price: AnyPriceStructure | AnyPricePlaceholder;
};

type MachinePricePlaceholder = {
    type: PricePlaceholderType.MACHINE_PRICE;
};

export const machinePricePlaceholderStruct: Describe<MachinePricePlaceholder> = object({
    type: literal(PricePlaceholderType.MACHINE_PRICE),
});

type AnyPricePlaceholder = MachinePricePlaceholder;

export const calculatedServicePriceBlockBasisStruct: Struct<CalculatedServicePriceBlock["basis"]> = union([
    enums(Object.values(ServicePriceUnit)),
    enums(Object.values(MachineCounterType)),
]);

export type DriverQueryServicePriceBlock = ServicePriceBlockBase & {
    price: AnyPriceStructure;
    driverQueryTemplate: AnyDriverQueryTemplate["id"];
};

export type ProtocolActionServicePriceBlock = ServicePriceBlockBase & {
    price: AnyPriceStructure;
    protocolActionConfigId: ProtocolActionConfig["id"];
};

export function isCalculatedServicePriceBlock(
    priceBlock: Partial<AnyPriceBlock>
): priceBlock is CalculatedServicePriceBlock {
    return "receiptText" in priceBlock && "basis" in priceBlock;
}

export function isDriverQueryServicePriceBlock(
    priceBlock: Partial<AnyPriceBlock>
): priceBlock is DriverQueryServicePriceBlock {
    return "receiptText" in priceBlock && "driverQueryTemplate" in priceBlock;
}

export function isProtocolActionServicePriceBlock(
    priceBlock: Partial<AnyPriceBlock>
): priceBlock is ProtocolActionServicePriceBlock {
    return "receiptText" in priceBlock && "protocolActionConfigId" in priceBlock;
}

export type AnyServicePriceBlock =
    | CalculatedServicePriceBlock
    | DriverQueryServicePriceBlock
    | ProtocolActionServicePriceBlock;

export type SimplePriceStructure = {
    type: PriceStructureType.SIMPLE;
    price: number;
};

export type TieredPriceStructure = {
    type: PriceStructureType.TIERED;
    price: ScalePrice[];
};

export type VolumePriceStructure = {
    type: PriceStructureType.VOLUME;
    price: ScalePrice[];
};

export type AnyPriceStructure = SimplePriceStructure | TieredPriceStructure | VolumePriceStructure;

const ScalePriceStruct: Describe<ScalePrice> = object({
    id: string(),
    upperBound: union([number(), literal(null)]),
    price: number(),
});

const VolumePriceStructureStruct: Describe<VolumePriceStructure> = object({
    type: literal(PriceStructureType.VOLUME),
    price: array(ScalePriceStruct),
});

const TieredPriceStructureStruct: Describe<TieredPriceStructure> = object({
    type: literal(PriceStructureType.TIERED),
    price: array(ScalePriceStruct),
});

const SimplePriceStructureStruct: Describe<SimplePriceStructure> = object({
    type: literal(PriceStructureType.SIMPLE),
    price: number(),
});

export const anyPriceStructureStruct = union([
    SimplePriceStructureStruct,
    TieredPriceStructureStruct,
    VolumePriceStructureStruct,
]);

export const workTypeRestrictionStruct: Describe<WorkTypeRestriction> = array(string());
