import { AnyDriverQueryTemplate, DriverQueryResourceWithAmountTemplate } from "./DriverQuery";
import { AnyPriceBlock, ProtocolActionConfig } from "./Service";
import { WorkTypeRestriction, isDriverQueryServicePriceBlock } from "./ServicePriceBlocks";
import { ServicePriceUnit } from "./ServicePriceUnit";
import { MachineCounterType } from "../MachineCounterTracking";
import { Resource } from "../Resource";

export type ResourcePriceBlock = {
    id: string; // uuid
};

export type CalculatedResourcePriceBlock = ResourcePriceBlock & {
    resource: CalculatedResourcePriceBlockResource;
    amount: number;
    basis:
        | ServicePriceUnit
        | MachineCounterType
        | CalculatedResourceDriverQueryBasis
        | CalculatedResourceProtocolActionBasis;
    workTypeRestriction: WorkTypeRestriction | null;
};

export enum CalculatedResourcePriceBlockResourceType {
    FIXED = "FIXED",
    DRIVER_QUERY = "DRIVER_QUERY",
}

export type CalculatedResourcePriceBlockResource =
    | {
          type: CalculatedResourcePriceBlockResourceType.FIXED;
          resourceId: Resource["id"];
          resourceVariantId: string;
      }
    | {
          type: CalculatedResourcePriceBlockResourceType.DRIVER_QUERY;
          driverQueryTemplateId: DriverQueryResourceWithAmountTemplate["id"];
      };

export type CalculatedResourceDriverQueryBasis = {
    driverQueryTemplate: AnyDriverQueryTemplate["id"];
};

export type CalculatedResourceProtocolActionBasis = {
    protocolActionConfigId: ProtocolActionConfig["id"];
};

export type DriverQueryResourcePriceBlock = ResourcePriceBlock & {
    driverQueryTemplate: DriverQueryResourceWithAmountTemplate["id"];
};

export function isCalculatedResourcePriceBlock(
    priceBlock: Partial<AnyPriceBlock>
): priceBlock is CalculatedResourcePriceBlock {
    return "basis" in priceBlock && "amount" in priceBlock;
}

export function isDriverQueryResourcePriceBlock(
    priceBlock: Partial<AnyPriceBlock>
): priceBlock is DriverQueryResourcePriceBlock {
    return !isDriverQueryServicePriceBlock(priceBlock) && "driverQueryTemplate" in priceBlock;
}

export type AnyResourcePriceBlock = CalculatedResourcePriceBlock | DriverQueryResourcePriceBlock;
