import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    IconDefinition,
    faCircleExclamation,
    faInfoCircle,
    faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import "./hint.scss";

type HintOwnProps = {
    severity: HintSeverity;
    color: HintColor;
    customIcon?: IconDefinition;
};

type HintProps = HintOwnProps & Omit<ComponentPropsWithoutRef<"div">, keyof HintOwnProps>;

export function Hint(props: HintProps) {
    const { children, severity, customIcon, color, ...restProps } = props;

    return (
        <div
            {...restProps}
            className={clsx(
                "farmact-hint",
                {
                    "farmact-hint--primary": color === "primary",
                    "farmact-hint--light": color === "light",
                    "farmact-hint--warning": color === "warning",
                    "farmact-hint--danger": color === "danger",
                },
                restProps.className
            )}>
            <FontAwesomeIcon icon={customIcon ?? getSeverityIcon(severity)} className="farmact-hint__icon" />
            <div className="farmact-hint__content">{children}</div>
        </div>
    );
}

export type HintSeverity = "info" | "warning" | "error";
export type HintColor = "light" | "primary" | "warning" | "danger";

function getSeverityIcon(severity: HintSeverity): IconProp {
    switch (severity) {
        case "info":
            return faInfoCircle;
        case "warning":
            return faTriangleExclamation;
        case "error":
            return faCircleExclamation;
    }
}
