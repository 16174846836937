import { Role } from "@farmact/model/src/model/Role";
import { User } from "firebase/auth";
import { AuthorizationCheck } from "./Session/withAuthorization";

export function onlyRoles(...possibleRoles: Role[]) {
    return (currentRole: Role | undefined): boolean => {
        if (!currentRole) {
            return false;
        }

        return possibleRoles.includes(currentRole);
    };
}

export function getAuthorizationGuard(...possibleRoles: Role[]): AuthorizationCheck {
    const guard = onlyRoles(...possibleRoles);
    return (user, role): boolean => {
        if (!user) {
            return false;
        }

        return guard(role);
    };
}

export function onlyCustomer(authUser: User, currentRole: Role) {
    return authUser && [Role.CUSTOMER, Role.NOTHING].includes(currentRole);
}

export function loggedIn(authUser: User) {
    return !!authUser;
}
