import { Order } from "@farmact/model/src/model/Order";
import { useOrganizationContext } from "@/components/organization/context/useOrganizationContext";
import { computed } from "@/util/functions";
import { getOrderMachines } from "@/util/orderUtils";

export function useOrderMachines(order: Order | undefined) {
    const { machines } = useOrganizationContext();

    return computed(() => {
        if (!order) {
            return [];
        }

        return getOrderMachines(order, machines);
    });
}
